@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400&display=swap");

* {
    font-family: Montserrat;
    letter-spacing: 2px;
}

body {
    overflow-x: hidden;
}

.home,
.Portrait-Container,
.Commercial-Container,
.Kids-Container,
.contact_container {
    /*margin-top: 5%;*/
    background-color: #ffffff;
    margin: auto;
    /*padding: 10px;*/
}

.about-container,
.about-us-text,
.contact_container {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    margin: auto;
}

.name {
    font-size: 10px;
}

/*Contact Page*/
.contact-details {
    padding-left: 10%;
}

.social-media-links-contact a {
    color: #000;
    font-size: 1.5rem;
    margin: 0rem 1.5rem 0rem 0rem;
}

/*-------------------------------------------------------*/

.slidein {
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: 1;
    animation-direction: alternate;
}

.slidein:hover {
    cursor: pointer;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 100%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
}

.reveal.active {
    transform: translateY(0);
    opacity: 1;
}

.contactForm {
    display: flex;
    justify-content: center;
}

.contactForm,
.about-container {
    /*margin: auto;*/
    padding: 5%;
}

.Portrait-Container {
    padding: 7% 3% 3% 3%;
    margin: auto;
}

.Kids-Container,
.Commercial-Container {
    padding: 5% 3% 3% 3%;
    margin: auto;
    background-color: white;
}

.Works-Container {
    padding: 7% 3% 3% 3%;
    margin: auto;
    background-color: white;
}

.carousel {
    margin: auto;
    padding-top: 3%;
    padding-bottom: 5%;
    width: 100%;
}

/* Default for less than 1500px (including mobile view) */
.cards-col {
    width: 100%; /* 1 column */
}

@media (min-width: 576px) {
    .cards-col {
        width: 100%; /* 1 column on mobile */
    }
}

@media (min-width: 768px) {
    .cards-col {
        width: 33.3333%; /* 3 columns for medium screens */
    }
}

@media (min-width: 1500px) {
    .cards-col {
        width: 20%; /* 5 columns for large screens */
    }
}

.carousel-img-wrapper {
    position: relative;
    height: 90vh;
    width: 100%;
}

.carousel-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.gallery {
    /*margin-top: 5%;*/
    padding: 5%;
}

.our_services,
.abt-me-text,
.films-text {
    margin: auto;
    padding: 5%;
    text-transform: uppercase;
    letter-spacing: 3px;
    /*width: 80%;*/
    /*margin-top: 5%;*/
}

.why_text {
    padding: 5%;
    text-align: center;
    letter-spacing: 3px;
}

.view_gallery,
.videos {
    width: 100%;
    /*height: 400px;*/
    padding: 6%;
    margin-top: 5%;
    /*margin-top: 5%;*/
    text-align: center;
    color: #000;
    /*background-color: #F0E7E3;*/
    background-color: #ffffff;
    /*color: black;*/
    /*width: 200px;*/
    /*height: 200px;*/
    /*background-color: #fff;*/
    /*border-radius: 8px;*/
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    /* Shadow effect */
    /*transform: rotateX(10deg) rotateY(10deg);*/
}

.gallery {
    padding-top: 10%;
}

.view_btn,
.contact_btn,
.view_review_btn,
.watch_btn,
.bck_btn,
.View-more {
    height: 50px;
    width: 155px;
    border-radius: 3px;
    background-color: #392313;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.videos {
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: row;
    /*justify-content: space-evenly;*/
}

.VideoList {
    padding: 2%;
    background-color: white;
}

.videos_content {
    padding-left: 20px;
    margin-left: 10%;
    text-align: justify;
}

.films_img {
    width: 850px;
    height: 500px;
}

/*.why_us{*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  width: 100%;*/
/*  margin: auto;*/
/*  margin-top: 5%;*/
/*  padding: 50px;*/
/*  background-color: #fcf1f4;*/
/*}*/
/*.why_content{*/
/*  !*margin-left: 10%;*!*/
/*}*/
/*.why_text{*/
/*  width: 40%*/
/*}*/

/*Menu*/
@media (max-width: 500px) {
    .Kids-Container,
    .Works-Container,
    .contact_container,
    .Portrait-Container,
    .Commercial-Container {
        padding-top: 25%;
        background-color: white;
    }

    .Image-view,
    .app-gallery,
    .Video,
    .Portfolio,
    .Wedding,
    .Ariel,
    .Concert,
    .Corporate,
    .Puberty {
        padding-top: 20%;
    }

    .review {
        padding-top: 20%;
    }

    .form-contact-page {
        width: 80%;
        margin: auto;
    }

    .headerMenu {
        display: none;
    }

    .mbl-nav {
        border: none;
    }

    Header {
        display: none;
    }

    .NavbarMenu {
        background-color: #f0e7e3;
        color: black;
        text-transform: uppercase;
    }

    .social-media-links-contact {
        text-align: center;
    }

    .form-contact-page {
        padding-top: 5%;
    }

    /*about image*/
    .abt-img {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .Abt-logo {
        width: 30%;
    }

    .about-us-text {
        width: 100%;
        height: 70px;
        padding-top: 5%;
        font-size: 25px;
    }

    .abt-cont {
        padding-top: 25%;
    }

    .app-gallery {
        width: 100%;
        margin: auto;
    }

    .View-more {
        font-size: 16px;
    }

    .contactForm {
        margin-right: 60%;
        margin-top: 30%;
    }

    /*Carousel prev and next*/
    .carousel-buttons {
        position: absolute;
        top: 45%;
        left: 2%;
        right: 2%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        color: white;
    }

    .prev-button,
    .next-button {
        background-color: transparent;
        border: none;
        font-size: 25px;
    }

    .prev-button:hover,
    .next-button:hover {
        color: #000000;
    }

    /*.carousel-img-wrapper{*/
    /*  height: 200px;*/
    /*  display: flex;*/
    /*  justify-content: center;*/
    /*  align-items: center;*/
    /*}*/
    /*  .carousel-img-wrapper img{*/
    /*    max-height: 100%;*/
    /*    max-width: 100%;*/
    /*    object-fit: fill;*/
    /*  }*/
    .Top-carousel {
        margin: auto;
        padding-top: 25%;
        /*padding-bottom: 10%;*/
        width: 100%;
    }

    .carousel-img-wrapper {
        position: relative;
        height: 40vh;
        width: 100%;
    }

    .carousel-buttons {
        top: 35%;
    }

    .carousel-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .gallery-image:hover {
        opacity: 0.8;
    }

    .top-section {
        flex-direction: column;
        gap: 5rem;
    }

    .tumbnail-video {
        height: 100%;
        width: 90%;
    }

    /*  Contact Page*/
    .container {
        flex-direction: column;
        /* Stack columns vertically on mobile */
    }
}

@media (min-width: 500px) {
    .right-column {
        flex: 1;
        /* Let the right column take up available space */
        min-width: 50%;
        /* Set a minimum width for the right column */
        /* Add other styles for the right column */
        margin-left: 15%;
    }

    .contact_container {
        padding-top: 7%;
        background-color: white;
    }

    .form-contact-page {
        width: 50%;
        margin: auto;
    }

    .ant-form-item-explain-error {
        margin-top: 19px;
    }

    .tumbnail-video {
        height: 250px;
        width: 80%;
    }

    .menuIcon {
        display: none;
    }

    .mbl-nav {
        display: none;
    }

    /*Navbar*/
    .NavbarMenu {
        font-size: 18px;
        /*border: none;*/
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
        padding-top: 1%;
        /*background-color:#F0E7E3;*/
    }

    .Menu .ant-menu-item:hover {
        opacity: 0.7;
    }

    .selected {
        position: relative;
    }

    .selected::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgba(129, 129, 129, 0.1);
        filter: blur(5px);
    }

    .header {
        background-color: rgba(255, 255, 255, 1);
        transition: background-color 0.3s ease;
    }

    .header.scroll {
        background-color: rgba(255, 255, 255, 0.8);
        /* Adjust the opacity as per your preference */
    }

    .abt-img {
        width: 100%;
    }

    .Abt-logo {
        width: 20%;
    }

    .about-us-text {
        width: 100%;
        height: 90px;
        padding-top: 2%;
        font-size: 30px;
    }

    /*Carousel prev and next*/
    .Top-carousel {
        padding-top: 2%;
    }

    .carousel-buttons {
        position: absolute;
        top: 50%;
        left: 2%;
        right: 2%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        color: white;
    }

    .prev-button,
    .next-button {
        background-color: transparent;
        border: none;
        font-size: 25px;
    }

    .prev-button:hover,
    .next-button:hover {
        color: #000000;
    }

    /*Gallery image transition*/
    .gallery-image {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s;
        border-radius: 5%;
    }

    .gallery-image:hover {
        transform: scale(1.1);
        opacity: 0.8;
    }

    /*.carousel-img-wrapper{*/
    /*  width:70%;*/
    /*  height: 500px;*/
    /*  display: flex;*/
    /*  justify-content: center;*/
    /*  align-items: center;*/
    /*}*/
    /*.carousel-img-wrapper img{*/
    /*  max-height: 100%;*/
    /*  max-width: 100%;*/
    /*  object-fit: fill;*/
    /*}*/
    .carousel-img-wrapper {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    /*.carousel-img-wrapper.slick-active {*/
    /*  opacity: 1;*/
    /*  transition: opacity 0.5s ease-in-out;*/
    /*}*/
}

/*Contact Form*/
.submit-button {
    height: 50px;
    width: 150px;
    border-radius: 3px;
    color: white;
    background-color: #392313;
    text-transform: uppercase;
    /*border-color: #392313;*/
    transition: background-color 0.3s ease;
}

/*.submit-button:hover,*/
/*.submit-button:focus {*/
/*  background-color: #A87550;*/
/*}*/
/*.submit-button:active,*/
/*.submit-button.active {*/
/*  background-color:  #A87550;*/
/*}*/

.form-item {
    opacity: 7;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.form-item.active {
    opacity: 1;
    transform: translateY(0);
}

/*About*/
.top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.left {
    flex: 3;
}

.right {
    flex: 2;
}

.carousel-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

/*Customer Review*/
.customer-reviews-container {
    overflow: auto;
    padding: 1rem;
    white-space: nowrap;
}

.customer-reviews {
    display: inline-block;
    white-space: normal;
}

.review-card {
    display: inline-block;
    vertical-align: top;
    margin-right: 1rem;
    width: 300px;
    transition: transform 0.3s ease;
    transform-origin: top center;
}

.review-card.active {
    transform: scale(1.1);
}

/*Footer*/
.app-footer {
    background-color: #333;
    color: #fff;
    padding: 2rem;
}

.app-footer-content {
    display: flex;
    justify-content: space-between;
}

.app-footer-section {
    margin-bottom: 2rem;
    color: #fff;
}

.app-footer-section h4 {
    color: #fff;
    margin-bottom: 1rem;
}

.app-footer-section p {
    margin-bottom: 0.5rem;
}

.social-media-links {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.social-media-links a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0.5rem;
}

.app-footer-bottom {
    background-color: #222;
    padding: 1rem 0;
    text-align: center;
}

.app-footer-bottom p {
    color: #fff;
    margin: 0;
}

.contactForm h3 {
    font-style: italic;
}

.view_gallery p {
    font-style: italic;
}

.Backbutton {
    padding: 2%;
    border-radius: 3px;
    color: #fdf6f7;
    background-color: #f0e7e3;
    text-transform: uppercase;
}

.gallery {
    background-color: white;
}

/*Contact Form*/
.Contact-Form {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    /*position: fixed;*/
    z-index: 1;
}

/*img-card*/

/*.custom-card {*/
/*  height: 300px; !* Set a fixed height for the card *!*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/
/*.card-image-container{*/
/*  flex: 1;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
/*.img-card{*/
/*  max-width: 100%;*/
/*  max-height: 100%;*/
/*  object-fit: contain;*/
/*}*/

.cards-col {
    width: 100%;
}

@media (min-width: 576px) {
    .cards-col {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .cards-col {
        width: 33.3333%;
    }
}

@media (min-width: 1500px) {
    .cards-col {
        width: 20%;
    }
}

/*cards*/
.cards-name {
    text-transform: uppercase;
}

.cards {
    position: relative;
}

.cards .content {
    display: none;
    /* Initially hide the content */
    position: absolute;
    top: 40%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 2px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
}

.cards:hover .content {
    display: block;
    /* Show the content on hover */
}

.hidden {
    display: none;
}

.WeddingCard:hover {
    opacity: 0.5;
}

/*Reviews*/
.customer-reviews-container {
    padding: 2rem;
}

.card {
    padding-top: 2%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
    border: none;
    text-align: center;
}

.card:hover {
    transform: scale(1.1);
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);*/
}

.card-other {
    opacity: 0.7;
    background-color: #f5f5f5;
}

.card-other.current {
    transform: scale(1.1);
    opacity: 1;
    background-color: transparent;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.arrow-icon {
    font-size: 24px;
    cursor: pointer;
    color: #000000;
}

/*Contact page*/

.container {
    display: flex;
    /* Use Flexbox for desktop layout */
    flex-wrap: wrap;
    /* Allow items to wrap to the next line */
}

.left-column {
    flex: 1;
    /* Let the left column take up available space */
    min-width: 50%;
    /* Set a minimum width for the left column */
    /* Add other styles for the left column */
}

.right-column {
    flex: 1;
    /* Let the right column take up available space */
    min-width: 50%;
    /* Set a minimum width for the right column */
    /* Add other styles for the right column */
    /*margin-left: 15%;*/
}
